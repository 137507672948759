@import '~/ui/assets/styles/colors.module.scss';

.snackbar {
  align-items: center;

  & [class*='MuiAlert-message'] {
    & button {
      margin-left: 20px;

      color: $color-green-6;
      border: 1px solid $color-green-7;
    }

    & button:hover {
      background-color: $color-green-8;
    }
  }
}
