@import '~/ui/assets/styles/colors.module.scss';

.wrapper {
  margin-bottom: 15px;
  background: $color-white;
  border-radius: 10px;
  box-shadow: 0px 1px 4px $color-grey-7;
  position: relative;
}

.paginationButton {
  display: flex;
  justify-content: center;
  padding: 0 15px 15px;
}

.topBlock {
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid $color-grey-4;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  background-color: $color-white;
  gap: 10px;

  & > div {
    width: 800px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.title {
  margin: 0;
  padding: 10px 0;
}

.noBorder {
  border: none;
}

.itemLabel {
  & div[class*='ListItem_label'] {
    width: 250px;
  }
}

.emptyState {
  padding: 30px 20px !important;
  width: 50%;
  margin: 0 auto;

  & > span {
    margin-top: 20px;
    font-size: 0.875rem;
    text-align: center;
  }
}
