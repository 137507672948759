.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerRightSide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.invisible {
  display: none;
}

.tabsWrapper {
  margin-bottom: 20px;
}
