.noDataImage {
  padding: 120px 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.125rem;

  & img {
    width: 215px;
    margin-bottom: 20px;
  }
}
