.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  object-fit: contain;
  height: 50px;
}
