@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: normal;
  font-weight: 200;
  src: local('Nunito'), url(../fonts/Nunito-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: italic;
  font-weight: 200;
  src: local('Nunito'), url(../fonts/Nunito-ExtraLightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: normal;
  font-weight: 300;
  src: local('Nunito'), url(../fonts/Nunito-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: italic;
  font-weight: 300;
  src: local('Nunito'), url(../fonts/Nunito-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src: local('Nunito'), url(../fonts/Nunito-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: italic;
  font-weight: 400;
  src: local('Nunito'), url(../fonts/Nunito-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: normal;
  font-weight: 600;
  src: local('Nunito'), url(../fonts/Nunito-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: italic;
  font-weight: 600;
  src: local('Nunito'), url(../fonts/Nunito-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
  src: local('Nunito'), url(../fonts/Nunito-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: italic;
  font-weight: 700;
  src: local('Nunito'), url(../fonts/Nunito-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: normal;
  font-weight: 800;
  src: local('Nunito'), url(../fonts/Nunito-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: italic;
  font-weight: 800;
  src: local('Nunito'), url(../fonts/Nunito-ExtraBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: normal;
  font-weight: 900;
  src: local('Nunito'), url('../fonts/Nunito-Black.ttf') format('trutype');
}
@font-face {
  font-family: 'Nunito';
  font-display: fallback;
  font-style: italic;
  font-weight: 900;
  src: local('Nunito'), url('../fonts/Nunito-BlackItalic.ttf') format('trutype');
}
