@import '~/ui/assets/styles/colors.module.scss';

.col {
  display: flex;
  flex-direction: column;
}

.wrapper {
  margin-bottom: 15px;
  background: $color-white;
  border-radius: 10px;
  box-shadow: 0px 1px 4px $color-grey-7;
}

.titleBlock {
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid $color-grey-4;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  background-color: $color-grey-22;
  color: $color-green-4;
}

.draftTitle {
  border-top-left-radius: 0px;
  border-top: 1px solid $color-grey-4;
  border-top-right-radius: 0px;
}

.inactiveTitle {
  color: $color-black;
  background-color: $color-white;
  border-bottom: none;
  font-size: 1.125rem;
}

.title {
  margin: 0;
  padding: 10px 0;
}

.archivedButtonWrapper {
  display: flex;
  justify-content: center;
}

.archivedButton {
  cursor: pointer;
  background-color: transparent;
  color: $color-red-2;
  display: flex;
  align-items: center;
  border: none;
  margin-bottom: 15px;
}

.noBorder {
  border: none;
}

.roundedBottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.roundedTop {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.buttonContainer {
  justify-content: center;
  display: flex;
  padding: 24px 0;
}

.emptyState {
  padding: 30px 20px !important;
  width: 85vw;

  & > span {
    margin-top: 20px;
  }
}

.planType {
  flex: 1;
  margin-left: 20px !important;
  color: $color-black;
  font-weight: 400 !important;
}
