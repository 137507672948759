@import '~/ui/assets/styles/colors.module.scss';

.container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 100px;
}
