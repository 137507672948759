.wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.buttonContainer {
  position: absolute;
  top: -142px;
  right: 0;
}

.modalTitle {
  text-align: center;
}

.modal {
  width: 60%;
}

.modalWrapper {
  padding: 20px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
}
