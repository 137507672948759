@import '~/ui/assets/styles/colors.module.scss';

.medicationDeliveryInfo {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  border-radius: 8px;
  justify-content: center;
  padding: 8px 10px;

  & div {
    align-items: center;
    display: flex;
    flex-direction: row;

    & span {
      margin-left: 10px;
      vertical-align: bottom;
    }
  }
}

.injectable {
  margin-left: 5px;
}

.date {
  color: $color-black-2;
}

.fullInfo {
  justify-content: space-between;
  background-color: $color-white-3;
}

.archived {
  background-color: $color-grey-15 !important;
  color: black !important;
}
