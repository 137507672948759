@import '~/ui/assets/styles/colors.module.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: stretch;
}

.alternativeValuesHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  align-self: stretch;
  margin-top: 10px;

  button {
    font-weight: 600;
  }
}

.items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  align-self: stretch;
  max-height: 240px;
  overflow: hidden auto;
  margin-right: -15px;
  padding-right: 15px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  align-self: stretch;
  margin-bottom: 10px;

  input::placeholder {
    color: black;
  }

  svg {
    font-size: 1.4rem;
  }
}
