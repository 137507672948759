@import '~/ui/assets/styles/colors.module.scss';

.header {
  font-weight: 300;
  display: flex;
  justify-content: space-between;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.buttonWrapper {
  margin-right: 15px;
}

.formWrapper {
  min-width: 100%;
  max-height: calc(100vh - 465px);
  overflow: scroll;
}

.text {
  font-weight: 600;
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.radioGroupContainer {
  display: flex !important;
  flex-direction: row !important;

  & > div:first-child {
    font-weight: 600;
    font-size: 0.83rem !important;
    color: $color-black-4;
    margin-top: 10px;

    &::after {
      content: ':';
    }
  }

  & > div {
    flex-direction: column !important;
    margin-right: 15px;
    background-color: transparent;

    & [class*='MuiFormControlLabel-label'] {
      font-size: 0.9rem;
    }

    & [class*='MuiRadio-root'] {
      padding: 5px;
    }
  }
}

.image {
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
  width: 30px;
  height: 30px;
}

.subTitle {
  font-weight: 600;
  margin: 10px 0;
}

.validationErrors {
  color: $color-red-2;
  margin: 0;
  padding-left: 10px;
}

.absoluteIcon {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 20px;
  width: 0;
  height: 0;
}

.errorMessage {
  color: $color-red-2;
  font-size: 0.75rem;
  margin: 0;
  padding-top: 0 !important;
  margin-top: -10px;
}

.teamMemberItem {
  padding: 10px;
}
