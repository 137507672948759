@import '~/ui/assets/styles/colors.module.scss';

.container {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  align-self: stretch;
  flex: 5;
  margin-right: 10px;
}

.list {
  color: $color-red-2;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.item {
  position: relative;
  margin-right: 5px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: $color-red-2;
  }
}

.tooltip {
  position: absolute;
  bottom: 30px;
  left: 70px;
  box-shadow: 0px 1px 4px $color-grey-7;
  background-color: $color-white;
  color: $color-black;
  padding: 10px;
  border-radius: 5px;
  display: none;
  z-index: 2;
}

.visible {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.title {
  font-weight: 600;
  font-size: 14px;
}

.note {
  margin-top: 5px;
}

.w100 {
  min-width: 100px;
}

.w200 {
  min-width: 200px;
}

.w300 {
  min-width: 350px;
}
