@import '~/ui/assets/styles/colors.module.scss';

.actionButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.link {
  text-decoration: none;
  color: $color-black;
  transition: 0.5s color;
}

.link:visited {
  color: $color-black;
}

.link:hover {
  color: $color-red-2;
  cursor: pointer;
}
