@import '~/ui/assets/styles/colors.module.scss';

.actionsCell {
  padding-left: 16px;
}

.linkView {
  text-decoration: none;
  color: $color-black;
  transition: color 0.5s;
  &:hover {
    color: $color-red-2 !important;
  }
  &:visited {
    color: $color-black;
  }
}

.restoreButton {
  padding: 0 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
