@import '~/ui/assets/styles/colors.module.scss';

.sidebar {
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  width: 256px;
  background-color: $color-white;
  z-index: 3;
}

.closed {
  width: 54px;
}

.inner {
  height: 100%;
}
