@import '~/ui/assets/styles/colors.module.scss';

.container {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background-color: $color-white-3;
  align-items: center;
  margin: 15px 0;
}

.message {
  padding-left: 10px;
  color: $color-red-2;
}
