@import '~/ui/assets/styles/colors.module.scss';

.container {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
}

.dragAndDropContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.dragAndDropDisplayContent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  z-index: 1;
}

.contentIcon {
  padding-right: 10px;
}

.contentTitle {
  font-size: 18px;
}

.contentDescription {
  font-size: 14px;
}

.dropzone {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  color: $color-grey;
  z-index: 2;
}

.dropzoneTarget {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.standartCursor {
  cursor: auto;
}

.draggingOverTargetClassName {
  background-color: rgba(255, 255, 255, 0.5);
}

.previewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
}

.imagePreview {
  flex: 1;
  height: 100%;
  width: 100%;
}

.image {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.buttonsWrapper {
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid $color-grey-6;
  background-color: $color-white-6;
  border-radius: 5px;
  position: absolute;
  bottom: 60px;
  left: 75px;
  z-index: 2;
}

.divider {
  display: inline-block;
  height: 30px;
  width: 1px;
  background-color: $color-grey-7;
}
