.modal {
  width: 30%;
  min-height: 300px;
}

.modalWrapper {
  padding: 20px;

  text-align: center;
}

.modalTitle {
  text-align: center;
  font-size: 1.3rem;
}

.container {
  display: flex;
  flex-direction: column;
}

.actionButtons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & button {
    margin: 0 5px !important;
  }
}
