@import '~/ui/assets/styles/colors.module.scss';

.resourcesFilters {
  margin-bottom: 20px;
  width: 100%;

  & div[class*='Input_inputContainer'] {
    background-color: $color-white !important;
  }
}

.selectContainer {
  & div[class*='container'] {
    background-color: $color-white !important;
    border-radius: 10px;
  }
  & div[class*='placeholder'] {
    color: $color-red-2 !important;
  }
}
