@import '~/ui/assets/styles/colors.module.scss';

.unreadMessages {
  background-color: $color-red-2;
  min-width: 19px;
  height: 19px;
  border-radius: 10px;
  border-width: 1px;
  border-color: $color-white;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  display: flex;
  align-self: flex-end;
}

.noBorder {
  border-color: transparent;
}

.inactive {
  background-color: $color-grey-4;
}

.count {
  font-size: 0.75rem;
  line-height: 14px;
  color: $color-white;
}

.inactiveCount {
  color: $color-grey-2;
}
