@import '~/ui/assets/styles/colors.module.scss';

.container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoSection {
  border-radius: 10px;
  background-color: $color-white;
  box-shadow: 0px 1px 4px $color-grey-7;
  margin-top: 20px;
  align-self: stretch;
  padding: 10px 10px 12px 20px;
  flex-direction: row;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 400;
}

.contentEnd {
  justify-content: flex-end;
  margin-top: 0px !important;
}

.collapsedInfoSection {
  padding: 0 10px;
  justify-content: space-between;
  background-color: $color-white-3;
  box-shadow: none;
}

.row {
  flex-direction: row;
  align-items: center;
  display: flex;
  flex: 1;
}

.column {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  align-self: stretch;
  flex: 5;
}

.wide {
  flex: 8;
}

.centerItems {
  align-self: center;
}

.right {
  max-width: 300px;
}

.general {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 15px;
}

.avatar {
  width: 50px;
  height: 50px;
  min-width: 50px;
}

.name {
  font-weight: 600;
  font-size: 14px;
}

.textItem {
  font-weight: 400;
  font-size: 14px;
  color: $color-grey-2;
}

.black {
  color: $color-black;
}

.red {
  color: $color-red-2 !important;
}

.marginRight {
  margin-right: 10px;
}

.collapseButton {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 10px;
  cursor: pointer;
}

.collapsedIcon {
  transform: scaleY(-1);
}

.hidden {
  opacity: 0;
  height: 0;
}

.link {
  text-decoration: none;
  color: $color-black;
}

.clientStatus {
  flex-direction: column;

  & > span {
    padding: 0;
    color: $color-grey-2;
  }
}

.toggle {
  align-self: flex-start !important;

  & [class*='MuiFormControlLabel-label'] {
    margin-left: 0 !important;
  }
}

.arrowRight {
  transform: rotate(0.5turn);
}
