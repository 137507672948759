@import '~/ui/assets/styles/colors.module.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-right: 70px;
  color: $color-black-2;
  background-color: $color-white-3;
  z-index: 5;
  padding: 0 15px;
}

.rightContainer {
  display: flex;
  align-items: center;
}

.spacer {
  width: 50px;
  transition: all 0.2s;

  &.expanded {
    width: 240px;
  }
}

.toggleBtn {
  color: $color-white !important;
}

.block {
  padding-left: 30px;
}

.inner {
  display: flex;
  align-items: center;
  color: $color-black-2;
  text-decoration: none;
  cursor: pointer;
}

.title {
  max-width: 300px;
  padding-left: 8px;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 300;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.notificationsButton {
  position: relative;
}

.poweredBy {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 70px 0 20px;

  & img {
    margin-left: 10px;
    height: 20px;
    object-fit: contain;
  }

  & p {
    font-size: 0.75rem;
  }
}
