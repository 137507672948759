@import '~/ui/assets/styles/colors.module.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.width {
  width: 49%;
}

.modalTitle {
  text-align: center;
}

.modal {
  width: 60%;
}

.modalWrapper {
  padding: 20px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
}

.selectContainer {
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;

  & div > div {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.severeAllergy {
  & h4 {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -10px;
    margin-right: -10px;
    background-color: $color-red-4;
  }
}
