@import '~/ui/assets/styles/colors.module.scss';

.tableContainer {
  position: relative;
}

.actionCell {
  padding-left: 16px;
}

.button {
  cursor: pointer;
  padding-left: 16px;
}

.link {
  color: black;
  text-decoration: none;

  &:hover {
    color: $color-red-2;
  }
}

.multiSelect {
  & div[class*='container'] {
    background-color: $color-white !important;
    border-radius: 10px;
  }

  & div[class*='ValueContainer'] {
    font-weight: 300 !important;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 100px;
}

.disabledButton {
  cursor: default;
  color: $color-grey-5;
}
