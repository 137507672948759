@import '~/ui/assets/styles/colors.module.scss';

.column {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  align-self: stretch;
  flex: 5;
  margin-right: 10px;
  position: absolute;
  top: -5000px;
  left: -5000px;

  @media print {
    position: initial;
  }
}

.marginRight {
  margin-right: 5px;
}

.row {
  font-weight: 600;
  font-size: 14px;
  color: $color-grey-2;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  max-width: 200px;
  flex-wrap: wrap;
}

.black {
  color: $color-black;
}

.name {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 200px;
  font-weight: 400;
}

.initialsItem {
  margin-bottom: 5px;
}
