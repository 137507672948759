.actionButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.selectContainer {
  display: flex;
  align-items: stretch;
  margin: 0 20px 30px;
  display: flex;
  flex-direction: column;

  & span {
    text-align: center;
    margin-bottom: 25px;
    margin-top: -25px;
    font-size: 16px;
  }

  & div > div {
    max-width: 100%;
    flex-basis: 100%;
  }
}
