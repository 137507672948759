@import '~/ui/assets/styles/colors.module.scss';

.tableContainer {
  overflow: visible !important;
}

.linkView {
  text-decoration: none;
  color: $color-black;
  transition: color 0.5s;
  &:hover {
    color: $color-red-2 !important;
  }
  &:visited {
    color: $color-black;
  }
}

.duplicateButton {
  cursor: pointer;
}

.disabled {
  color: $color-grey-4 !important;
  cursor: default;

  & svg {
    fill: $color-grey-4 !important;
  }
}
