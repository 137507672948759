@import '~/ui/assets/styles/colors.module.scss';

.actionButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.link {
  text-decoration: none;
  color: $color-black;
  transition: color 0.5s;
  &:hover {
    color: $color-red-2 !important;
  }
  &:visited {
    color: $color-black;
  }
}

.column {
  & > button:nth-child(2) {
    margin-top: 10px;
  }
}

.globeIcon {
  margin-left: 3px;
  width: 22px;
  height: 22px;
}

.disabled {
  pointer-events: none;
}
