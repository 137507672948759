.toggleContainer {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  & [class*='MuiFormControlLabel-root'] {
    align-items: center;
  }
  & [class*='MuiFormControlLabel-label'] {
    font-size: 0.875rem;
  }

  & [class*='MuiTypography-root'] {
    margin-right: 5px;
  }
}
