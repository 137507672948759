$color-white: white;
$color-white-2: #fffbfa;
$color-white-3: #fff1ee;
$color-white-4: #f4f4f4;
$color-white-5: #fff9f9;
$color-white-6: rgba(247, 242, 244, 0.9);
$color-white-7: #fafafa;

$color-black: black;
$color-black-2: #323232;
$color-black-3: rgba(0, 0, 0, 0.2);
$color-black-4: rgba(0, 0, 0, 0.7);
$color-black-5: rgba(0, 0, 0, 0.08);
$color-black-6: rgba(0, 0, 0, 0.38);

$color-grey: grey;
$color-grey-2: #757575;
$color-grey-3: #868383;
$color-grey-4: #e0e0e0;
$color-grey-5: #b5b5b5;
$color-grey-6: #eaeaea;
$color-grey-7: #c4bcbb;
$color-grey-8: rgba(196, 196, 196, 0.15);
$color-grey-9: #f3f3f3;
$color-grey-10: #ebebeb;
$color-grey-11: #f0f0f0;
$color-grey-12: #323232;
$color-grey-13: #a2a2a2;
$color-grey-14: #dadcde;
$color-grey-15: #3232321a;
$color-grey-16: #736f6f;
$color-grey-17: #f5f5f5;
$color-grey-18: #e4e7eb;
$color-grey-19: #f3f3f3;
$color-grey-20: #6e7781;
$color-grey-21: #929698;
$color-grey-22: #f6f6f6;
$color-grey-23: #757373;
$color-grey-24: #e8e6e6;

$color-green: green;
$color-green-2: #d0f2ee;
$color-green-3: #009d8a;
$color-green-4: #027186;
$color-green-5: #16c0ab;
$color-green-6: #5eb660;
$color-green-7: rgba(94, 182, 96, 0.5);
$color-green-8: rgba(94, 182, 96, 0.1);

$color-red: red;
$color-red-2: #d83713;
$color-red-3: #f44336;
$color-red-4: rgba(216, 54, 18, 0.3);

$color-orange: orange;
$color-orange-2: #ffe5bf;
$color-orange-3: #ff9800;
$color-orange-4: #b36011;
$color-orange-5: rgba(179, 96, 17, 0.2);
$color-orange-6: rgba(179, 96, 17, 0.6);
$color-orange-7: #bd7653;
$color-orange-8: #fb7e40;

$color-blue: blue;
$color-blue-2: #d6e4fa;
$color-blue-3: #0c79d0;
$color-transparent: rgba(216, 54, 18, 0);

:export {
  colorWhite: $color-white;
  colorGrey: $color-grey-2;
  colorLightGrey: $color-grey-5;
  colorRed: $color-red-2;
  colorOrange: $color-orange;
  colorTransparentRed: $color-red-4;
  colorTransparentBlack: $color-black-5;
  colorBlack: $color-black;
  colorGreen: $color-green-3;
  colorGoldenBrown: $color-orange-4;
  colorGrey12: $color-grey-12;
  colorTransparent: $color-transparent;
}
