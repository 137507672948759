.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100vh - 100px);
  overflow: hidden;
  margin-left: -50px;
  padding-left: 50px;
}

.scrollableContainer {
  height: 100%;
}
