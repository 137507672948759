.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonsWrapper {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.buttonMargin {
  margin-right: 10px;
}

.modal {
  width: 50%;
}

.input {
  margin-bottom: 20px;
}
