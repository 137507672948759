@import '~/ui/assets/styles/colors.module.scss';

.wrapper {
  border-radius: 10px;
  box-shadow: 0px 1px 4px #c4bcbb;
  display: flex;
  align-self: stretch;
  background-color: $color-white;
  margin: 0 0 15px 20px;
}

.innerWrapper {
  flex-direction: column;
  display: flex;
  align-self: stretch;
  flex: 1;
}

.topBlock {
  border-bottom: 1px solid #e0e0e0;
  padding: 0 20px;
}

.item {
  & > div > div {
    width: auto;
    font-weight: 300;
    color: $color-grey-2;
  }

  white-space: pre-wrap;
}

.row {
  flex-direction: row;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
}

.form {
  padding: 15px;
}

.showMoreButton {
  font-weight: 600;
  margin: 0 auto 10px;
}

.historyList {
  padding: 10px;
  padding-top: 0;
  display: flex;
  align-self: stretch;
  flex-direction: column;
}

.statusHistoryItem {
  display: flex;
  flex-direction: row;
  align-self: stretch;
}

.text {
  flex: 1;
  padding: 5px;
  font-size: 0.875rem;
}

.secondaryText {
  color: $color-grey-2;
}

.noDateSelection {
  opacity: 0;
  cursor: default;
}

.noDateSelectionContainer {
  margin-left: -50%;
}

.underline {
  text-decoration: underline;
}
