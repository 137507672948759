@import '~/ui/assets/styles/colors.module.scss';

.selectControl {
  width: 30%;
  text-align: left;
  border: 0;
}

.label {
  position: absolute;
  top: -6px;
  left: 10px;
  padding: 0 4px !important;
  font-size: 12px !important;
  background: $color-white;
  z-index: 1;
}

.labelError {
  color: $color-red-2 !important;
}

.error {
  margin-left: 14px !important;
}

.helperText {
  font-weight: 300;
  text-align: center;
  border-bottom: 1px solid black;
  padding: 10px 0px;
}
