@import '~/ui/assets/styles/colors.module.scss';

.indicatorContainer {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 6px;
  height: 6px;
  background-color: $color-red-2;
  border-radius: 3px;
  outline: 1px solid $color-white-3;
}
