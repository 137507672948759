@import '~/ui/assets/styles/colors.module.scss';

.aiContainer {
  min-width: 450px;
  padding: 15px 30px;
  margin-top: 40px;

  input::placeholder,
  textarea::placeholder {
    color: black;
  }
}

.title {
  margin-bottom: 0px;
}

.buttonWrapper {
  margin: 20px 0;
}

.error {
  margin-top: 20px;
  font-size: 0.7rem;
  color: $color-red-3;
}

.inputWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.backButton {
  color: $color-red-2 !important;
  font-weight: 500 !important;
}

.formTooltip {
  max-height: none !important;
  max-width: 400px !important;
  padding: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.toggle {
  align-self: flex-end !important;

  & [class*='MuiFormControlLabel-label'] {
    margin-left: 0 !important;
  }
}

.aiResponseWrapper {
  white-space: pre;
  max-width: 100%;
  text-wrap: auto !important;
  overflow: auto;
}
