@import '~/ui/assets/styles/colors.module.scss';

.item {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
  border-bottom: 1px solid $color-grey-4;
  font-weight: 300;
  align-items: center;
}

.label {
  font-size: 0.9rem;
  width: 100px;
  min-width: 100px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  overflow-wrap: normal;

  & span {
    color: $color-grey;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: 300;
  }
}

.moreLabelWidth {
  min-width: 150px;
}

@media screen and (min-width: 1280px) {
  .moreLabelWidth {
    min-width: 230px;
  }
}

@media screen and (min-width: 1536px) {
  .moreLabelWidth {
    min-width: 250px;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 81%;
  flex: 1;
}

.injection {
  margin-right: 5px;
}

.content {
  color: $color-grey;
}

.grey {
  color: $color-grey;
}

.additionalText {
  font-weight: 300;
  text-align: right;
  margin: 0;
  color: $color-red-3;
}

.restoreButton {
  cursor: pointer;
  margin-left: 10px;
}

.item:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.item:last-of-type {
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.datesRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 5px;

  & > * {
    flex: 1;
    color: $color-grey;
  }
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 25px;
}

.archiveIcon {
  width: 24px;
  height: 24px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: $color-grey-12;
  -webkit-mask-image: url('../../../assets/images/archiveIcon.svg');
  mask-image: url('../../../assets/images/archiveIcon.svg');
}

.disabled {
  background-color: $color-grey-13;
}
