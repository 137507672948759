@import '~/ui/assets/styles/colors.module.scss';

.labelWrapper {
  width: 350px;
}
.grey {
  color: $color-grey-3;
}

.black {
  color: $color-black;
}
