.input {
  width: 100%;
  padding: 15px;
  background-color: #f8f8f8;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 10px;
  border-color: #d2d2d2;
  border-style: solid;
  margin-right: 15px;
}
