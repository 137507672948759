@import '~/ui/assets/styles/colors.module.scss';

.datePickerControl {
  & div[class*='MuiOutlinedInput-root']:focus {
    overflow: hidden;
    outline-color: $color-red;
  }
  z-index: 5;
}

.label {
  position: absolute;
  top: -6px;
  left: 10px;
  padding: 0 4px !important;
  font-size: 12px !important;
  background: $color-white;
  z-index: 1;
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute !important;
  top: -4px;
  right: 0;
  color: $color-blue !important;
}

.datePicker.error {
  border-color: $color-red;
}

.errorText {
  margin-left: 14px !important;
}

.calendar {
  font-family: Nunito;
  font-weight: 300;
  font-size: 0.875rem;
  border-radius: 16px;
  overflow: hidden;
  padding: 10px;
  z-index: 5;
  border-color: transparent;
  box-shadow: 0px 1px 4px $color-grey-7;
  display: flex;
  flex-direction: column;

  & > div > button {
    top: 5px;

    & span::before {
      border-color: $color-red-2;
      border-width: 1.5px 1.5px 0 0;
      height: 7px;
      width: 7px;
      border-radius: 1px;
    }
  }

  @mixin bg-component {
    content: '';
    width: 27px;
    height: 27px;
    position: absolute;
    background-color: $color-red-2;
    z-index: -1;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 50%;
  }

  & [class*='react-datepicker__header'] {
    background-color: $color-white !important;
    border-bottom: none;
    padding-bottom: 0;

    & > div:first-child {
      font-weight: 400;
    }
    & > div:last-child {
      & > div {
        font-size: 0.75rem;
        color: $color-grey-20;
      }
    }
  }

  & [class*='react-datepicker__day'] {
    margin: 0 !important;
    padding: 0.1rem 0.2rem;
    width: 30px;
    height: 100%;
    position: relative;
    border-radius: 2rem;
    outline: none;
  }

  & [class*='react-datepicker__day--outside-month'] {
    color: $color-grey-21;
  }

  & [class*='react-datepicker__day--selected'] {
    background-color: $color-red-2;
  }

  & [class*='react-datepicker__day--keyboard-selected'] {
    background-color: $color-grey-11;
    color: $color-black;

    &:hover {
      color: $color-white;

      &::before {
        @include bg-component;
      }
    }
  }
}

.calendarTitle {
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500;
  color: $color-grey-20;
}

.textButton {
  color: $color-black;
  cursor: pointer;
  font-weight: 500;
  padding: 0 5px;
  margin-bottom: -2px;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.disabled {
  color: $color-grey-20;
  cursor: default;
}
