@import '~/ui/assets/styles/colors.module.scss';

.selectControl {
  width: 100%;
  text-align: left;
}

.label {
  position: absolute;
  top: -6px;
  left: 10px;
  padding: 0 4px !important;
  font-size: 12px !important;
  background: $color-white;
  z-index: 1;
}

.labelError {
  color: $color-red-2 !important;
}

.error {
  margin-left: 14px !important;
}

.helperText {
  font-weight: 300;
  text-align: center;
  border-bottom: 1px solid $color-grey-4;
  padding: 10px 0px;
}

.selectAll {
  font-weight: 400;
  text-align: left;
  border-bottom: 1px solid $color-grey-4;
  padding: 10px 0px 10px 12px;

  &:hover {
    background-color: $color-black-5;
    cursor: default;
  }
}

.up {
  transform: scaleY(-1);
}

.menuOption:active,
.menuOption:hover,
.menuOption:focus {
  background-color: $color-black-5;
}

.disabled {
  color: $color-grey-5 !important;
  background-color: transparent !important;

  & [class*='checked'] {
    color: $color-grey-5 !important;
  }
}

.disabled:active,
.disabled:focus {
  background-color: 'white' !important;
}

.checked {
  background-color: $color-black-5;
}
