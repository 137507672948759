@import '~/ui/assets/styles/colors.module.scss';

.signIn {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 520px;
  padding: 50px;
  text-align: center;
}

.logo {
  width: 100px;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  top: 10%;
  left: 50%;
}

.title {
  text-align: left;
  padding: 30px 0 15px;
  font-size: 22px;
  font-weight: 500;
}

.row {
  padding-top: 30px;
}

.btn {
  width: 100%;
}

.serverError {
  padding-top: 10px;
  color: $color-red;
}

.divider {
  margin-top: 25px;
  height: 1px;
  width: 100%;
  background-color: $color-grey-4;
}

.forgotPasswordText {
  text-align: left;
  color: $color-grey-3;
  font-weight: 300;
}

.forgotPasswordButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: $color-red-2;
}
