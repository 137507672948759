.contentContainer {
  & [class*='content'] {
    display: flex;
    align-self: stretch;
  }

  & [class*='contentWrapper'] {
    max-width: none;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  align-self: stretch;
  gap: 10px;
}
