@import '~/ui/assets/styles/colors.module.scss';

.container {
  display: flex;
  flex-direction: row;
  & span {
    margin-top: 10px;
    padding-right: 3px;
  }
}

.hiddenItemsContainer {
  background-color: $color-red-2 !important;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: $color-white;
  justify-content: center;
  align-items: center;
  display: flex;
}
