@import '~/ui/assets/styles/colors.module.scss';

.initialsContainer {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  background-color: $color-white-3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  cursor: pointer;
}

.label {
  color: $color-red-2;
}

.active {
  border: 1px solid $color-red-2;
}
