.formTitle {
  font-weight: 300;
  margin-bottom: 20px;
}

.paperContainer {
  margin-top: 20px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonsWrapper {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.buttonWrapper {
  margin-right: 15px;
}

.link {
  text-decoration: none;
}

.combinedInputWrapper {
  display: flex;
}
