@import '~/ui/assets/styles/colors.module.scss';

.wrapper {
  display: flex;
  flex: 1;
  position: relative;
}

.col {
  border-radius: 10px;
  width: 45%;
  background-color: $color-white;
  box-shadow: 0px 1px 4px $color-grey-7;
}

.header {
  padding: 5px 15px;
  border-bottom: 1px solid $color-grey-4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.archivedButtonWrapper {
  display: flex;
  justify-content: center;
}

.archivedButton {
  cursor: pointer;
  background-color: transparent;
  color: $color-red-2;
  display: flex;
  align-items: center;
  border: none;
  margin-bottom: 15px;
}

.noBorder {
  border-bottom: none;
}

.center {
  text-align: center;
}

.margin {
  margin-right: 15px !important;
}

.buttonsWrapper {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50vh;
}
