.customSingleValue {
  display: flex;
  align-items: center;

  .label {
    font-weight: 500;
    margin-right: 5px;
  }

  .description {
    font-weight: 300;
    color: #736f6fdb;
  }
}
