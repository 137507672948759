@import '~/ui/assets/styles/colors.module.scss';

.buttonsWrapper {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}

.margin {
  margin: 0 10px !important;
}

.marginVertical {
  margin: 20px 0 !important;
}

.red {
  color: $color-red-2 !important;
  font-weight: 500 !important;
}

.checkboxDescription {
  color: $color-grey;
  font-size: 0.75rem;
  text-align: center;
  margin-bottom: 15px;
}
