.modal {
  width: 550px;
  min-height: 200px;
}

.modalWrapper {
  padding: 20px;
  text-align: center;
  position: relative;

  & p,
  span,
  pre {
    text-align: left;
    text-wrap: wrap;
    max-width: 100%;
  }

  & pre {
    font-weight: 400;
  }

  & span {
    float: left;
  }
}

.modalTitle {
  text-align: center;
  font-size: 1.3rem;
}

.container {
  display: flex;
  flex-direction: column;
}

.actionButtons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  & button {
    margin: 0 5px !important;
    align-self: center;
  }

  & span {
    font-size: 0.875rem;
    font-weight: 300;
  }
}

.readAll {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -10px;
  bottom: 10px;
}

.label {
  padding-right: 5px;
}

.message {
  font-weight: 400;
}
