@import '~/ui/assets/styles/colors.module.scss';

.container {
  display: flex;
  flex-direction: row;
  & span {
    margin-top: 10px;
    padding-right: 3px;
  }
}

.chip {
  margin-right: 2px;
  height: 25px !important;
}
