@import '~/ui/assets/styles/colors.module.scss';

.wrapper {
  margin-bottom: 15px;
  background: $color-white;
  border-radius: 10px;
  box-shadow: 0px 1px 4px $color-grey-7;
}

.topBlock {
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid $color-grey-4;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  background-color: $color-white;
}

.title {
  margin: 0;
  padding: 10px 0;
}

.archivedButtonWrapper {
  display: flex;
  justify-content: center;
}

.archivedButton {
  cursor: pointer;
  background-color: transparent;
  color: $color-red-2;
  display: flex;
  align-items: center;
  border: none;
  margin-bottom: 15px;
}

.noBorder {
  border: none;
}
