@import '~/ui/assets/styles/colors.module.scss';

.modal {
  width: 50%;
  min-height: 400px;
}

.modalWrapper {
  padding: 20px;
}

.modalTitle {
  text-align: center;
}

.selectContainer {
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;

  & div > div {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.actionButtons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & button {
    margin: 0 5px !important;
  }
}

.buttonsWrapper {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  gap: 10px;
}
