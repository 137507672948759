@import '~/ui/assets/styles/colors.module.scss';

.checkboxGroup {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
}

.groupTitle {
  font-size: 1rem;
  font-weight: 700 !important;
  background-color: $color-grey-6;
  padding: 9px 14px;
  border-radius: 8px;
}
