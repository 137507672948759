@import '~/ui/assets/styles/colors.module.scss';

.tableContainer {
  position: relative;
  overflow: visible !important;
}

.actionsCell {
  padding-left: 16px;
}

.actionButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.absoluteButton {
  position: absolute;
  top: -118px;
  right: -1px;
  z-index: 1;
}

.link {
  text-decoration: none;
  color: $color-black;
  transition: 0.5s color;
}

.link:visited {
  color: $color-black;
}

.link:hover {
  color: $color-red-2;
  cursor: pointer;
}

.restoreButton {
  padding: 0 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
