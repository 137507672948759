@import '~/ui/assets/styles/colors.module.scss';

.wrapper {
  border-radius: 10px;
  box-shadow: 0px 1px 4px #c4bcbb;
  display: flex;
  align-self: stretch;
  background-color: $color-white;
  margin: 0 0 15px 20px;
}

.innerWrapper {
  flex-direction: column;
  display: flex;
  align-self: stretch;
  flex: 1;
}

.topBlock {
  border-bottom: 1px solid #e0e0e0;
  padding: 0 20px;
}

.item {
  & > div > div {
    width: auto;
    font-weight: 300;
    color: $color-grey-2;
  }

  white-space: pre-wrap;
}

.row {
  flex-direction: row;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
}

.modalTitle {
  text-align: center;
}

.modal {
  width: 60%;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
}
