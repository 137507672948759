@import '~/ui/assets/styles/colors.module.scss';

.monthControls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  flex-direction: row;
  display: flex;
  font-weight: 300;
  margin-right: 10px;
  min-width: 30px;
}

.bold {
  font-weight: 600;
}

.reflected {
  transform: scale(-1);
}

.controls {
  width: 70px;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}
