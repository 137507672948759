.customOption {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  span {
    display: block;
  }
  .label {
    font-weight: 500;
    text-wrap: nowrap;
  }
  .description {
    font-weight: 300;
    color: #736f6fdb;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
  }
}
