@import '~/ui/assets/styles/colors.module.scss';

.previewContainer {
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.headerContainer {
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.editedBy {
  font-weight: 400;
  font-size: 13px;
  color: $color-grey-5;
  font-style: italic;
  margin: 0px !important;
}

.noShowField {
  div {
    width: 100%;
    max-width: 100% !important;
    justify-content: space-between;
    text-align: left;
    &:last-child {
      text-align: right;
    }
  }
}

.noShowReason {
  color: $color-red-2;
}

.objectiveLabel > div {
  align-items: flex-start;
}

.objectiveLabel p {
  margin-top: 0;
}

.objectiveTitle {
  font-weight: 600;
}

.green {
  color: $color-green-3;
}
