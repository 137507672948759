@import '~/ui/assets/styles/colors.module.scss';

.container {
  width: 100%;
  height: 100px;
  border: 1px solid $color-grey-4;
  border-radius: 10px;
  overflow: hidden;
}

.dragAndDropContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.dragAndDropDisplayContent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  z-index: 1;
}

.contentIcon {
  padding-right: 10px;
}

.contentTitle {
  font-size: 18px;
}

.contentDescription {
  font-size: 14px;
  font-weight: 300;
}

.dropzone {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  color: $color-grey;
  z-index: 2;
}

.dropzoneTarget {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.draggingOverTargetClassName {
  background-color: rgba(255, 255, 255, 0.5);
}

.previewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
}

.imagePreview {
  flex: 1;
  height: 100%;
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hidden {
  display: none;
}
