.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabsWrapper {
  margin-bottom: 15px;
}

.assignUserButton {
  margin-right: 10px !important;
}
