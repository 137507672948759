@import '~/ui/assets/styles/colors.module.scss';

.form {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  min-width: 70%;
  padding: 10px;
  border-radius: 10px;
  background-color: $color-white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(0, 0, 0, 0.05);

  h4 {
    padding: 0;
    margin: 10px 0 5px 0;
  }
}

.buttonHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.rowRadioGroup {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  margin-bottom: 5px;
  gap: 15px;
}

.selectWidth {
  max-width: 30%;
}

.selectGroupWidth {
  max-width: 60%;
}

.column {
  display: flex !important;
  flex-direction: column !important;
}
