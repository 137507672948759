@import '~/ui/assets/styles/colors.module.scss';

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonsWrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.button {
  margin-right: 15px;
}

.link {
  text-decoration: none;
}
