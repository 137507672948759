@import '~/ui/assets/styles/colors.module.scss';

@media screen and (max-width: 950px) {
  .column {
    width: 100% !important;
  }
}
.column {
  width: 49%;
  min-width: 500px;
  margin-bottom: 10px;
}

.dischargeDetails {
  margin-top: 20px;
  width: 90%;

  & > h4 {
    text-align: center;
    font-weight: 300;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 15px;
  border-bottom: 1px solid $color-grey-4;
  font-weight: 300;
}

.item:first-of-type {
  border-radius: 20px 20px 0 0;
}

.listItemBlock {
  border-radius: 10px;
  box-shadow: 0px 1px 4px $color-grey-7;
  margin-bottom: 15px;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
}

.uploaderWrapper {
  margin-top: 50px;
}

.red {
  color: $color-red-2;
}
