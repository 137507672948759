.modal {
  min-width: 70vw;
  min-height: 400px;
  position: relative;

  & a {
    display: none;
  }
  & #pdf-zoom-reset {
    & button {
      display: none;
    }
  }
}

.modal {
  & [id^='pdf-zoom-reset'] {
    display: none;
  }
}

.loader {
  margin: auto;
}
