.modal {
  min-width: 600px;
}

.inner {
  padding-bottom: 20px;
}

.description {
  text-align: center;
  padding: 30px 0;
  font-size: 16px;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
}

.btnCancel {
  margin-left: 10px!important;
}
