@import '~/ui/assets/styles/colors.module.scss';

.signUp {
  width: 30vw;
  min-width: 450px;
  padding: 15px 30px;
  margin-top: 40px;
}

.title {
  margin-bottom: 0px;
}

.subTitle {
  font-size: 0.7rem;
  color: $color-grey-2;
  font-weight: 300;
  margin-top: 2px;
}

.buttonWrapper {
  margin: 20px 0;
}

.error {
  font-size: 0.7rem;
  color: $color-red-3;
}

.inputWrapper {
  margin-bottom: 15px;
}

.backButton {
  color: $color-red-2 !important;
  font-weight: 500 !important;
}
