@import '~/ui/assets/styles/colors.module.scss';

.inputContainer {
  width: 100%;
}

.overwrittenInputContainer {
  width: 70%;
}

.labelClassName {
  color: red;
}

.icon {
  color: $color-blue !important;
}

.root {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-width: 0px !important;
}

.notchedOutline {
  border-right-width: 0 !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}
