@import '~/ui/assets/styles/colors.module.scss';

.actionsCell {
  width: 100px !important;

  & svg {
    fill: $color-red-2 !important;
    color: $color-red-2 !important;
  }
}

.lastHeaderCell {
  & > span {
    padding-right: 15px;
  }
}

.lastHeaderCellAdmin {
  & > span {
    padding-right: 70px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.firstCell {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > svg {
    margin-right: 20px;
  }
}

.iconBlack {
  width: 24px;
  height: 24px;
  background-color: $color-black;
  mask-size: contain;
}

.downloadIcon {
  -webkit-mask: url('../../../../assets/images/download.svg') no-repeat center;
  mask: url('../../../../assets/images/download.svg') no-repeat center;
}

.editIcon {
  -webkit-mask: url('../../../../assets/images/editGrey.svg') no-repeat center;
  mask: url('../../../../assets/images/editGrey.svg') no-repeat center;
}

.trashIcon {
  -webkit-mask: url('../../../../assets/images/trashBlack.svg') no-repeat center;
  mask: url('../../../../assets/images/trashBlack.svg') no-repeat center;
}

.viewOpenIcon {
  -webkit-mask: url('../../../../assets/images/viewOpen.svg') no-repeat center;
  mask: url('../../../../assets/images/viewOpen.svg') no-repeat center;
}

.buttonCell {
  cursor: pointer;

  &:hover path {
    fill: $color-red-4 !important;
  }
}

.cellContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrowUpIcon {
  min-width: 15px;
  margin-left: 7px;

  & path {
    fill: $color-red-2 !important;
    stroke: 2px;
  }
}

.rotate {
  transform: rotate(0.5turn);
}

.arrowHidden {
  & path {
    fill: transparent !important;
  }
}
