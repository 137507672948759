@import '~/ui/assets/styles/colors.module.scss';

.layout {
  display: flex;
  min-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 50px;
  background-color: $color-white-2;
  padding-left: 256px;
}

.mainExtended {
  padding-left: 55px;
}

.content {
  flex-grow: 1;
  padding: 75px 25px 25px;
}
