@import '~/ui/assets/styles/colors.module.scss';

.wrapper {
  margin-bottom: 15px;
  background: $color-white;
  border-radius: 10px;
  box-shadow: 0px 1px 4px $color-grey-7;
}

.topBlock {
  align-items: flex-start;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid $color-grey-4;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  background-color: $color-white;
  flex-direction: column;
  margin-bottom: 20px;
}

.detailsBlock {
  padding-left: 15px;
  padding-bottom: 10px;
}

.title {
  margin: 0;
  padding: 10px 0;
}

.row {
  padding-right: 0 !important;
}
