@import '~/ui/assets/styles/colors.module.scss';

.breadcrumbsContainer {
  margin-bottom: 20px;
}

.navLink {
  text-decoration: none;
  color: $color-grey-3;
  font-weight: 300;
}

.activeNavLink {
  color: $color-black-2;
}
