@import '~/ui/assets/styles/colors.module.scss';

.modal {
  width: 50%;
}

.modalWrapper {
  padding: 20px;
}

.modalTitle {
  text-align: center;
}

.selectContainer {
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;

  & div > div {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.actionButtons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & button {
    margin: 0 5px !important;
  }
}

.toggle {
  margin-left: -15px !important;

  & span:last-child {
    padding-right: 5px;
  }
}

.availableFileTypes {
  font-size: 0.75rem;
  text-align: right;
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}
