@import '~/ui/assets/styles/colors.module.scss';

.datePickerControl {
  & div[class*='MuiOutlinedInput-root']:focus {
    overflow: hidden;
    outline-color: $color-red;
  }
  z-index: 5;
}

.label {
  position: absolute;
  top: -6px;
  left: 10px;
  padding: 0 4px !important;
  font-size: 12px !important;
  background: $color-white;
  z-index: 1;
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute !important;
  top: -4px;
  right: 0;
  color: $color-blue !important;
}

.datePicker.error {
  border-color: $color-red;
}

.errorText {
  margin-left: 14px !important;
}

.calendar {
  font-family: Nunito;
  font-weight: 300;
  font-size: 0.875rem;
  border-radius: 16px;
  overflow: hidden;
  padding: 10px;
  z-index: 5;
  border-color: transparent;
  box-shadow: 0px 1px 4px $color-grey-7;

  & > button {
    top: 14px;

    & span::before {
      border-color: $color-red-2;
      border-width: 1.5px 1.5px 0 0;
      height: 7px;
      width: 7px;
      border-radius: 1px;
    }
  }

  @mixin bg-component {
    content: '';
    width: 27px;
    height: 27px;
    position: absolute;
    background-color: $color-red-2;
    z-index: -1;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 50%;
  }

  & [class*='react-datepicker__header'] {
    background-color: $color-white !important;
    border-bottom: none;
    padding-bottom: 0;

    & > div:first-child {
      font-weight: 400;
    }
    & > div:last-child {
      & > div {
        font-size: 0.75rem;
        color: $color-grey-20;
      }
    }
  }

  & [class*='react-datepicker__day'] {
    margin: 0 !important;
    padding: 0.2rem;
    width: 30px;
    height: 100%;
    position: relative;

    &:hover {
      background-color: transparent;
    }
  }

  & [class*='react-datepicker__day--outside-month'] {
    color: $color-grey-21 !important;
  }

  & [class*='react-datepicker__day--in-selecting-range'] {
    background-color: transparent;
    color: $color-black;

    &:hover {
      background-color: transparent;
      color: $color-black;
    }
  }

  & [class*='react-datepicker__day--keyboard-selected'] {
    background-color: transparent;
    color: $color-black;

    &:hover {
      color: $color-white;

      &::before {
        @include bg-component;
      }
    }
  }
  & [class*='react-datepicker__navigation--years-upcoming'],
  [class*='react-datepicker__navigation--years-previous'] {
    position: relative;
    &:after {
      position: absolute;
      top: -5px;
      left: 0;
      right: 0;
      content: '‸';
      text-indent: 0;
      font-size: 25px;
    }
  }

  & [class*='react-datepicker__navigation--years-previous'] {
    transform: rotate(180deg);
  }

  & [class*='react-datepicker__year-option'] {
    & a {
      height: 20px !important;
    }
  }

  & [class='react-datepicker__year-dropdown'] {
    top: 10px !important;
    box-shadow: 0px 1px 4px #aeaeae;
    border: none;
    background-color: $color-white;
  }

  & [class*='react-datepicker__day--in-range'] {
    color: $color-white !important;
    background-color: transparent;

    &::before {
      @include bg-component;
      width: 32px;
      border-radius: 0;
    }
  }

  & [class*='react-datepicker__day--selecting-range-start'] {
    color: $color-white !important;
    background-color: transparent;

    &::before {
      @include bg-component;
    }

    &:hover {
      background-color: transparent;
      color: $color-white;

      &::before {
        @include bg-component;
      }
    }
  }

  & [class*='react-datepicker__day--range-start'] {
    &::before {
      @include bg-component;
      width: 32px;

      border-top-left-radius: 45% !important;
      border-bottom-left-radius: 45% !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:hover {
      &::before {
        width: 32px;
      }
    }
  }

  & [class*='react-datepicker__day--range-end'] {
    &::before {
      @include bg-component;
      width: 32px;

      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 45% !important;
      border-bottom-right-radius: 45% !important;
    }

    &:hover {
      &::before {
        width: 32px;
      }
    }
  }

  & [class*='react-datepicker__day--range-start react-datepicker__day--range-end'] {
    &::before {
      border-radius: 50% !important;
      width: 27px;
    }

    &:hover {
      &::before {
        border-radius: 50% !important;
        width: 27px;
      }
    }
  }
}
