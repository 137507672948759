.loader {
  // need to have padding to avoid loader dynamic height change issue
  // this issue occurs because mui loader icon uses rotate animation
  // animation: MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite;
  padding: 10px;
  text-align: center;
}

.loaderContainer {
  min-height: 100px;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
