@import '~/ui/assets/styles/colors.module.scss';

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 0.875rem;
  max-height: 150px;
  overflow: auto;
  padding: 22px 20px;
}

.icon {
  margin-left: 10px;
}

.tooltip {
  border-radius: 16px !important;
  border: 1px solid rgba(0, 0, 0, 0.13);
  box-shadow: 0px 10px 15px rgba(91, 78, 78, 0.15);
}
