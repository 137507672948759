.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonWrapper {
  margin-right: 10px !important;
}

.locationGroups {
  & strong:not(:first-child)::before {
    content: ', ';
    font-weight: 300;
  }

  & strong {
    margin-right: 5px;
  }
}

.nameCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
