@import '~/ui/assets/styles/colors.module.scss';

.datePickerControl {
  & div[class*='MuiOutlinedInput-root']:focus {
    overflow: hidden;
    outline-color: $color-red;
  }
}

.label {
  position: absolute;
  top: -6px;
  left: 10px;
  padding: 0 4px !important;
  font-size: 12px !important;
  background: $color-white;
  z-index: 1;
}

.inputWrapper {
  position: relative;
}

.datePicker {
  font-family: Nunito;
  font-weight: 300;
  width: 100%;
  height: 40px;
  padding: 10.5px 40px 10.5px 10.5px;
  font-size: 16px;
  border: 1px solid $color-grey-4;
  border-radius: 10px;
  outline: none;

  &:hover {
    border-color: $color-black;
  }

  &:focus {
    border-color: $color-red-3;
    border-width: 2px;
  }
}

.icon {
  position: absolute !important;
  top: -4px;
  right: 0;
  color: $color-blue !important;
}

.datePicker.error {
  border-color: $color-red;
}

.errorText {
  margin-left: 14px !important;
}
