@import '~/ui/assets/styles/colors.module.scss';

.scrollableContainer {
  max-height: 200px;
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 10px 0;

  & > :last-child {
    border-bottom: 1px solid $color-grey-4;
  }
}

.itemContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: stretch;
  padding: 7px 19px;
  align-items: center;
  border: 1px solid $color-grey-4;
  border-bottom: none;
  cursor: pointer;
}

.itemLabel,
.itemDescription {
  flex: 1;
  padding-right: 10px;
}

.selected {
  background-color: $color-white-3;
  position: relative;

  & svg {
    position: absolute;
    right: 20px;
    width: 18px;
    height: 18px;
  }
}

.emptyList {
  justify-content: center;
  align-items: center;

  & > :last-child {
    border-bottom: none;
  }
}
