@import '~/ui/assets/styles/colors.module.scss';

.roleSelect {
  padding: 20px;
  background-color: white;

  & .header {
    margin-bottom: 0;
  }
}

.itemsWrapper {
  border-radius: 20px !important;
  border: 1px solid $color-grey-4;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row {
  padding-top: 20px;
}
