@import '~/ui/assets/styles/colors.module.scss';

.uploadContainer {
  position: relative;
  width: 180px;
  height: 180px;
  background-color: $color-grey;
}

.label {
  display: block;
  font-size: 15px;
  font-weight: 500;
}

.previewWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 1;
}

.btnsWrapper {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 1;
}

.image {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.removeWrapper {
  padding-top: 5px;
}

.remove {
  width: 100%;
}

.imagePreview {
  flex: 1;
  height: 100%;
  width: 100%;
}
