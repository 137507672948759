@import 'fonts.scss';
@import 'colors.module.scss';

* {
  box-sizing: border-box;
}

body,
pre {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  background-color: $color-white-2;
  color: $color-black;
}

pre {
  background-color: transparent;
}

.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker__triangle {
  display: none;
}
